<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Domains</h3>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn small depressed color="accent" class="mr-2" @click="addDomainForm.dialog = true">
            <v-icon left>mdi-plus</v-icon>Add Domain
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="tableHeadersDomain"
          :items="customer.domains"
          no-data-text="No domains found"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  class="mr-2"
                  @click="openEditDomain(item.id)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  v-on="on"
                  @click="
                      archiveDomainDialog = true;
                      archiveDomainData = item;
                    "
                >
                  <v-icon x-small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="archiveDomainDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Domain</v-card-title>
        <v-card-text>
          Are you sure you want to delete
          {{ archiveDomainData ? archiveDomainData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archiveDomainDialog = false">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="archiveDomainLoading"
            @click="archiveDomain()"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDomainForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="addDomain" method="post">
          <v-card-title>
            <span class="headline">Add New Domain</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Domain Name*"
                  type="text"
                  v-model="addDomainForm.domain_name"
                  outlined
                  dense
                  :error="addDomainForm.errors.hasOwnProperty('domain_name')"
                  :error-messages="addDomainForm.errors['domain_name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Manager/Register"
                  type="text"
                  v-model="addDomainForm.manager_register"
                  outlined
                  dense
                  :error="
                      addDomainForm.errors.hasOwnProperty('manager_register')
                    "
                  :error-messages="addDomainForm.errors['manager_register']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Type"
                  type="text"
                  v-model="addDomainForm.type"
                  outlined
                  dense
                  class
                  :error="addDomainForm.errors.hasOwnProperty('type')"
                  :error-messages="addDomainForm.errors['type']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Hosting By"
                  type="text"
                  v-model="addDomainForm.hosting_by"
                  outlined
                  dense
                  class
                  :error="addDomainForm.errors.hasOwnProperty('hosting_by')"
                  :error-messages="addDomainForm.errors['hosting_by']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Hosting Package"
                  type="text"
                  v-model="addDomainForm.hosting_package"
                  outlined
                  dense
                  :error="
                      addDomainForm.errors.hasOwnProperty('hosting_package')
                    "
                  :error-messages="addDomainForm.errors['hosting_package']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Server Name"
                  type="text"
                  v-model="addDomainForm.server_name"
                  outlined
                  dense
                  class
                  :error="addDomainForm.errors.hasOwnProperty('server_name')"
                  :error-messages="addDomainForm.errors['server_name']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="addDomainForm.dialog = false">Close</v-btn>
            <v-btn color="accent" text type="submit" :loading="addDomainForm.loading">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDomainForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="editDomain" method="post">
          <v-card-title>
            <span class="headline">Edit Domain</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Domain Name*"
                  type="text"
                  v-model="editDomainForm.domain_name"
                  outlined
                  dense
                  :error="editDomainForm.errors.hasOwnProperty('domain_name')"
                  :error-messages="editDomainForm.errors['domain_name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Manager/Register"
                  type="text"
                  v-model="editDomainForm.manager_register"
                  outlined
                  dense
                  :error="
                      editDomainForm.errors.hasOwnProperty('manager_register')
                    "
                  :error-messages="editDomainForm.errors['manager_register']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Type"
                  type="text"
                  v-model="editDomainForm.type"
                  outlined
                  dense
                  class
                  :error="editDomainForm.errors.hasOwnProperty('type')"
                  :error-messages="editDomainForm.errors['type']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Hosting By"
                  type="text"
                  v-model="editDomainForm.hosting_by"
                  outlined
                  dense
                  class
                  :error="editDomainForm.errors.hasOwnProperty('hosting_by')"
                  :error-messages="editDomainForm.errors['hosting_by']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Hosting Package"
                  type="text"
                  v-model="editDomainForm.hosting_package"
                  outlined
                  dense
                  :error="
                      editDomainForm.errors.hasOwnProperty('hosting_package')
                    "
                  :error-messages="editDomainForm.errors['hosting_package']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Server Name"
                  type="text"
                  v-model="editDomainForm.server_name"
                  outlined
                  dense
                  class
                  :error="editDomainForm.errors.hasOwnProperty('server_name')"
                  :error-messages="editDomainForm.errors['server_name']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="editDomainForm.dialog = false">Close</v-btn>
            <v-btn color="accent" text type="submit" :loading="editDomainForm.loading">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      tableHeadersDomain: [
        { text: "Domain Name", value: "domain_name" },
        { text: "Manager/Registrar", value: "manager_register" },
        { text: "Type", value: "type" },
        { text: "Hosting By", value: "hosting_by" },
        { text: "Hosting Package", value: "hosting_package" },
        { text: "Server Name", value: "server_name" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      addDomainForm: {
        dialog: false,
        domain_name: null,
        manager_register: null,
        hosting_package: null,
        server_name: null,
        hosting_by: null,
        customerId: null,
        type: null,
        loading: false,
        errors: {},
      },
      editDomainForm: {
        dialog: false,
        domain_name: null,
        manager_register: null,
        hosting_package: null,
        server_name: null,
        type: null,
        hosting_by: null,
        loading: false,
        errors: {},
      },
      archiveDomainDialog: false,
      archiveDomainLoading: false,
      archiveDomainData: null,
    };
  },

  methods: {
    archiveDomain: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      const domainId = this.archiveDomainData.id;

      this.archiveDomainLoading = true;
      this.$store
        .dispatch("customers/archiveDomain", {
          appId,
          domainId,
          customerId,
        })
        .then(() => {
          this.archiveDomainData = null;
          this.archiveDomainLoading = false;
          this.archiveDomainDialog = false;
        });
    },

    addDomain: function () {
      const customerId = this.$route.params.customerId;
      const appId = this.$route.params.id;
      const domain_name = this.addDomainForm.domain_name;
      const manager_register = this.addDomainForm.manager_register;
      const hosting_package = this.addDomainForm.hosting_package;
      const server_name = this.addDomainForm.server_name;
      const hosting_by = this.addDomainForm.hosting_by;
      const type = this.addDomainForm.type;

      this.addDomainForm.loading = true;

      this.$store
        .dispatch("customers/addDomain", {
          appId,
          domain_name,
          manager_register,
          hosting_package,
          server_name,
          hosting_by,
          type,
          customerId,
        })
        .then(() => {
          this.addDomainForm = {
            dialog: false,
            domain_name: null,
            manager_register: null,
            hosting_package: null,
            server_name: null,
            type: null,
            hosting_by: null,
            customerId: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addDomainForm.errors = err.response.data.errors;
          this.addDomainForm.loading = false;
        });
    },

    openEditDomain: function (id) {
      const domain = this.customer.domains.find((c) => c.id === id);

      this.editDomainForm = {
        dialog: true,
        domainId: id,
        domain_name: domain.domain_name,
        manager_register: domain.manager_register,
        hosting_package: domain.hosting_package,
        server_name: domain.server_name,
        type: domain.type,
        hosting_by: domain.hosting_by,
        loading: false,
        errors: {},
      };
    },
    editDomain: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      const domainId = this.editDomainForm.domainId;

      const domain_name = this.editDomainForm.domain_name;
      const manager_register = this.editDomainForm.manager_register;
      const hosting_package = this.editDomainForm.hosting_package;
      const server_name = this.editDomainForm.server_name;
      const hosting_by = this.editDomainForm.hosting_by;
      const type = this.editDomainForm.type;

      this.editDomainForm.loading = true;

      this.$store
        .dispatch("customers/editDomain", {
          appId,
          customerId,
          domain_name,
          manager_register,
          hosting_package,
          server_name,
          hosting_by,
          type,
          domainId,
        })
        .then(() => {
          this.editDomainForm = {
            dialog: false,
            domain_name: null,
            manager_register: null,
            hosting_package: null,
            server_name: null,
            type: null,
            hosting_by: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editDomainForm.errors = err.response.data.errors;
          this.editDomainForm.loading = false;
        });
    },
  },
};
</script>
